<!--
 * @Description:
 * @Autor: Zhongyu
 * @Date: 2021-09-01 14:33:54
 * @LastEditors: Lizy
 * @LastEditTime: 2021-12-10 09:53:06
-->
<template>
  <div class="user-info">
    <div class="personal-center-title">我的资料</div>
    <div class="user-info-body">
      <div class="left">
        <div class="info">
          <el-avatar
            :src="$utils.picturePrefix(userData.nickPhoto)"
            v-if="!userData.openId"
          ></el-avatar>
          <el-avatar :src="userData.nickPhoto" v-if="userData.openId"></el-avatar>
          <div class="info-right">
            <div class="name-code">
              <div class="name text-ellipsis" :title="userData.name">
                {{ userData.name }}
              </div>
              <div
                class="earnest"
                @click="goMarginList"
                v-if="getUser.margin > 0"
              >
                <span> 保证金：¥</span>
                <span>{{ getUser.margin }}</span>
                <!-- <i class="iconfont icon-fuzhi" @click="copyInviteCode"></i> -->
              </div>
              <div
                class="earnest"
                v-else
                @click="
                  $router.push({
                    path: '/register',
                    query: {
                      type: '1',
                    },
                  })
                "
              >
                <p class="join">注册合伙人</p>
              </div>
            </div>
            <div class="level">
              <div class="level-left">
                <i class="iconfont icon-shifu"></i>
              </div>
              <div class="level-right">
                {{ userData.level && userData.level.name }}
              </div>
            </div>
            <div class="right-bottom">
              <!-- <div class="level">
                <div class="level-left">
                  <i class="iconfont icon-shifu"></i>
                </div>
                <div class="level-right">
                  {{ userData.level && userData.level.name }}
                </div>
              </div> -->
              <div class="code">
                邀请码：<span ref="inviteCode">{{ userData.inviteCode }}</span>
                <i
                  class="iconfont icon-fuzhi space"
                  @click="copyInviteCode"
                ></i>
              </div>
              <el-popover
                placement="right-start"
                title=""
                width="200"
                trigger="hover"
                :content="upgradeProcess"
              >
                <div slot="reference" class="help">如何工匠升级？</div>
              </el-popover>
            </div>
          </div>
        </div>
        <div class="form">
          <el-form
            ref="userForm"
            label-position="right"
            label-width="80px"
            :model="userData"
            :rules="rules"
          >
            <el-form-item label="姓名:" prop="name">
              <el-input
                v-model="userData.name"
                :disabled="!isUpdate"
              ></el-input>
            </el-form-item>
            <el-form-item label="手机号:">
              <el-input v-model="userData.phone" disabled></el-input>
              <span class="update" @click="handleUpdatePhone">修改</span>
            </el-form-item>
            <el-form-item label="工作区域:">
              <!-- <el-cascader
                v-model="userData.address"
                disabled
                filterable
                :options="getAddress"
                style="width: 100%"
              ></el-cascader> -->
              <el-cascader
                v-model="userData.address"
                placeholder="填写工作区域"
                filterable
                :options="getAddress"
                disabled
                style="width: 100%"
              ></el-cascader>
              <!-- <el-input
                v-model="userData.address"
                :disabled="!isUpdate"
              ></el-input> -->
            </el-form-item>
            <el-form-item v-show="!isUpdate">
              <el-button type="primary" @click="isUpdate = true">
                修改
              </el-button>
            </el-form-item>
            <el-form-item v-show="isUpdate">
              <el-button @click="isUpdate = false">取消</el-button>
              <el-button type="primary" @click="updateUser">保存</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div class="right">
        <div class="level-exp">
          <!-- <div class="exp-progress">
            <span class="level-text">L1</span>
            <el-progress :percentage="50" :show-text="false"></el-progress>
            <span class="level-text">L2</span>
          </div>
          <div class="exp-text">
            <div class="exp-text-title">收益</div>
            <div class="exp-text-level">7500/15000</div>
          </div> -->
          <!-- <div class="exp-progress">
            <span class="level-text">L1</span>
            <el-progress
              :percentage="
                userData && userData.level
                  ? (userData.allMoney / userData.level.nextNumerical) * 100
                  : 0
              "
              :show-text="false"
            ></el-progress>
            <span class="level-text">L2</span>
          </div>
          <div class="exp-text">
            <div class="exp-text-title">等级</div>
            <div class="exp-text-level">
              {{ userData && userData.level && userData.allMoney }}/{{
                userData && userData.level && userData.level.nextNumerical
              }}
            </div>
          </div> -->
        </div>
      </div>
    </div>
    <update-phone ref="updatePhone"></update-phone>
  </div>
</template>

<script>
import updatePhone from "@/views/personalCenter/userInfo/updatePhone";
import { session } from "@/utils/sessions";

export default {
  components: {
    updatePhone,
  },
  data() {
    return {
      isUpdate: false,
      userData: {
        name: "",
        phone: "",
        address: "",
      },
      rules: {
        name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
      },
      // 保证金
      marginList: {
        margin: "",
      },
      upgradeProcess: "",
    };
  },
  watch: {
    // isPower
    "$store.getters.user": {
      handler(newDate) {
        this.userData = newDate;
       
        // if (this.userData.address) {
        //   this.userData.address = JSON.parse(this.userData.address);
        // }
      },
      // 对象内部的属性监听，也叫深度监听
      deep: true,
    },
  },
  computed: {
    getAddress() {
      return this.$store.getters.address || [];
    },
    getUser() {
      return this.$store.getters.user;
    },
  },
  mounted() {
    this.userData = this.$store.getters.user || {};
     console.log("udata",this.userData);
    // if (this.userData.address) {
    //   this.userData.address = JSON.parse(this.userData.address);
    // }

    this.getUserInfo();
    this.getIntroduce();
  },
  created() {
    this.getSigningProcess();
  },
  methods: {
    /**
     * 打开修改手机号页面
     */
    handleUpdatePhone() {
      this.$refs.updatePhone.init(this.userData.phone);
    },

    getIntroduce() {
      this.$api.getIntroduce().then(res => {
        this.upgradeProcess = res.data.upgradeProcess;
      });
    },

    /**
     * 复制邀请码
     */
    copyInviteCode() {
      let div = this.$refs.inviteCode;
      if (document.body.createTextRange) {
        let range = document.body.createTextRange();
        range.moveToElementText(div);
        range.select();
      } else if (window.getSelection) {
        let selection = window.getSelection();
        let range = document.createRange();
        range.selectNodeContents(div);
        selection.removeAllRanges();
        selection.addRange(range);
      } else {
        console.warn("none");
      }
      document.execCommand("Copy");
      this.$message({
        message: "恭喜你，复制邀请码成功！",
        type: "success",
      });
    },

    getUserInfo() {
      this.$api.getUserInfo({ id: this.userData.id }).then(user => {
        session.setUsers(user.data);
        this.$store.dispatch("getUser", true);
      });
    },

    /**
     * 修改用信息
     */
    updateUser() {
      this.$refs.userForm.validate(valid => {
        if (valid) {
          let addressArr = [...this.getAddress];
          let area = [...this.userData.address];
          let addressStr = "";
          let i = 0;
          area.map(item => {
            let addressJson = addressArr.find(nape => {
              return nape.value == item;
            });
            if (addressJson) {
              if (i > 0) {
                addressStr += ",";
              }
              addressStr += addressJson.label;
              i++;
              if (addressJson.children) {
                addressArr = [...addressJson.children];
              }
            }
          });
          let param = {
            id: this.userData.id,
            name: this.userData.name,
            address: addressStr,
          };
          const loading = this.$loading({
            lock: true,
            text: "修改中...",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
          });
          this.$api
            .changeCustomer(param)
            .then(() => {
              this.getUserInfo();
              this.$message({
                message: "恭喜您，信息修改成功！",
                type: "success",
              });
              this.isUpdate = false;
            })
            .catch(err => {
              this.$message.error(err?.msg);
            })
            .finally(() => {
              loading.close();
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 获取保证金
    getSigningProcess() {
      this.$api.getSigningProcess().then(res => {
        let { code, data } = res;
        if (code == 200) {
          this.marginList = data;
          console.log(this.marginList.margin);
        }
      });
    },
    // 跳转至保证金页面
    goMarginList() {
      if (this.getUser && this.getUser.isPartner) {
        this.$router.push(`/personal-Center/cashDeposit`);
      } else {
        this.$message.warning("您成为城市合伙人的申请正在审核中...");
      }
    },
  },
};
</script>

<style lang="less">
@import "./index.less";
</style>
