<!--
 * @Description:
 * @Autor: Zhongyu
 * @Date: 2021-09-05 20:39:21
 * @LastEditors: Lizy
 * @LastEditTime: 2021-12-10 14:33:56
-->
<template>
  <div>
    <modal
      width="480px"
      :visible="visible"
      title="修改手机号"
      @close="handleClose"
    >
      <div class="modal-body">
        <el-form
          class="modal-form"
          label-position="right"
          ref="ruleForm"
          :model="form"
          :rules="rules"
          label-width="135px"
        >
          <el-form-item label="当前手机号:" prop="oldPhone">
            <div>{{ form.oldPhone }}</div>
          </el-form-item>
          <el-form-item label="当前手机验证码:" prop="oldCode">
            <el-input
              class="yzm-input"
              v-model="form.oldCode"
              placeholder="请输入当前手机验证码"
            >
              <el-link
                type="primary"
                slot="append"
                :class="{ 'link-disabled': oldIsDisabled }"
                @click="oldSendSms"
              >
                {{ oldButtonText }}
              </el-link>
            </el-input>
          </el-form-item>
          <el-form-item label="新手机号:" prop="newPhone">
            <el-input
              v-model="form.newPhone"
              placeholder="请输入新手机号"
            ></el-input>
          </el-form-item>
          <el-form-item label="新手机验证码:" prop="newCode">
            <el-input
              class="yzm-input"
              v-model="form.newCode"
              placeholder="请输入新手机验证码"
            >
              <el-link
                type="primary"
                :class="{ 'link-disabled': newIsDisabled }"
                slot="append"
                @click="newSendSms"
              >
                {{ newButtonText }}
              </el-link>
            </el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="visible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="onSubmit">
          确 定
        </el-button>
      </span>
    </modal>
  </div>
</template>

<script>
import Modal from "@/components/Modal";
export default {
  components: {
    Modal
  },
  data() {
    var checkPhone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("新手机号不能为空"));
      } else {
        if (this.checkMobile(value)) {
          callback();
        } else {
          return callback(new Error("请输入正确的新手机号"));
        }
      }
    };
    return {
      visible: false,
      form: {
        oldPhone: "",
        oldCode: "",
        newPhone: "",
        newCode: ""
      },
      oldButtonText: "发送验证码",
      oldFlag: true,
      oldIsDisabled: false,
      newButtonText: "发送验证码",
      newFlag: true,
      newIsDisabled: false,
      rules: {
        oldCode: [
          { required: true, message: "当前手机验证码不可为空", trigger: "blur" }
        ],
        newPhone: [{ validator: checkPhone, trigger: "blur" }],
        newCode: [
          { required: true, message: "新手机验证码不可为空", trigger: "blur" }
        ]
      }
    };
  },
  mounted() {},
  methods: {
    init(phone) {
      this.form.oldPhone = phone;
      this.visible = true;
    },

    handleClose() {
      this.visible = false;
    },

    onSubmit() {
      if(this.form.oldPhone == this.form.newPhone){
        this.$message.error('新手机号码与当前手机号码一致');
        return;
      }
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          const loading = this.$loading({
            lock: true,
            text: "修改中...",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)"
          });
          let param = {
            phone: this.form.oldPhone,
            code: this.form.oldCode,
            newPhone: this.form.newPhone,
            newcode: this.form.newCode
          };
          this.$api
            .changePhone(param)
            .then(() => {
              // this.getUserInfo();
              this.$parent.getUserInfo();
              this.$message({
                message: "恭喜您，修改手机号码成功！",
                type: "success"
              });
              this.visible = false;
            })
            .catch(err => {
              this.$message.error(err?.msg);
            })
            .finally(() => {
              loading.close();
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 当前手机号
    oldSendSms() {
      if (!this.oldFlag) {
        return false;
      }
      let oldPhone = this.form.oldPhone;
      this.sendSms(oldPhone, 'old', () => {
        this.oldSendSmsText();
      });
    },

    oldSendSmsText() {
      let self = this;

      let time = 60;
      self.oldButtonText = "已发送";
      self.oldIsDisabled = true;
      if (self.oldFlag) {
        self.oldFlag = false;
        let timer = setInterval(() => {
          time--;
          self.oldButtonText = time + " 秒";
          if (time === 0) {
            clearInterval(timer);
            self.oldButtonText = "重新获取";
            self.oldIsDisabled = false;
            this.oldFlag = true;
          }
        }, 1000);
      }
    },

    // 当前手机号
    newSendSms() {
      if (!this.newFlag) {
        return false;
      }
      let newPhone = this.form.newPhone;
      if (this.checkMobile(newPhone)) {
        this.sendSms(newPhone, 'new', () => {
          this.newSendSmsText();
        });
      } else {
        this.$refs.ruleForm.validateField("newPhone");
      }
    },

    newSendSmsText() {
      let self = this;
      let time = 60;
      self.newButtonText = "已发送";
      self.onewIsDisabled = true;
      if (self.newFlag) {
        self.newFlag = false;
        let timer = setInterval(() => {
          time--;
          self.newButtonText = time + " 秒";
          if (time === 0) {
            clearInterval(timer);
            self.newButtonText = "重新获取";
            self.newIsDisabled = false;
            this.newFlag = true;
          }
        }, 1000);
      }
    },

    // 发送验证码
    sendSms(phone, type,callback) {
      const loading = this.$loading({
        lock: true,
        text: "获取验证码中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
      let params;
      type == 'old' ? params = { phone } : params = { phone:phone, status: 2 };
      this.$api
        .sendSms(params)
        .then(() => {
          callback();
        })
        .catch(msg => {
          this.$message.error(msg?.msg);
        })
        .finally(() => {
          loading.close();
        });
    },
    // 验证手机号
    checkMobile(str) {
      let reg = /^1[3-9][0-9]\d{8}$/;
      if (reg.test(str)) {
        return true;
      } else {
        return false;
      }
    }
  }
};
</script>

<style lang="less" scoped></style>
